/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
@charset 'UTF-8';

/* Layout
===================================================*/
html{
	max-width: 640px;
}
body{
	font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', Osaka, 'メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic', Geneva, Arial, Verdana, sans-serif;
	font-size: 13px;
	line-height: 1.5;
	letter-spacing: .1ex;
	color: $default;
	background: #ffffff none center top repeat;
	-webkit-text-size-adjust: 100%;
	@include font-smoothing(antialiased);
	@include placeholder(#aaaaaa);
	a,
	a:link,
	a:visited{
		text-decoration: none;
		color: $hover;
	}
	a:hover,
	a:active{
		text-decoration: underline;
		color: $hover;
	}
	a{
		img{
			transition: .3s;
			opacity: 1;
		}
	}
	a:hover{
		img{
			opacity: .8;
		}
	}
	*{
		box-sizing: border-box;
	}
	img{
		max-width: 100%;
		vertical-align: top;
	}
	i{
		vertical-align: -15%;
	}
}

header{
	position: fixed;
	z-index: 9999;
	top: 0;
	border-bottom: 2px solid #437b34;
}

section{
	padding: 0;
	&.catch{
		padding: 21% 0 0 0;
	}
	&.lead{
		padding: 1rem 1rem;
		img{
			margin: .8rem 0;
		}
	}
	&.entry{
		position: relative;
		a{
			position: absolute;
			top: 35.3%;
			left: 24.4%;
			width: 72.8%;
		}
	}
	&.point{
		padding: 1rem 1rem;
		img{
			margin: .8rem 0;
		}
	}
	&.voice{
		padding: 1rem 1rem;
	}
	&.shop{
		padding: 1rem 1rem;
	}
	&.form{
		padding: 1rem;
		p{
			&.campaign{
				font-weight: bold;
				margin-top: 1rem;
				padding: 1rem;
				color: #fdf100;
				border-radius: 5px;
				background-color: #ff4a4a;
				@include font-size(14);
				strong{
					font-weight: bold;
				}
				span{
					&.caption{
						font-weight: normal;
						display: inline-block;
						padding-top: .5rem;
						color: #ffffff;
						@include font-size(10);
					}
				}
			}
			&.lead{
				padding: 1rem 0;
				color: #437b34;
				color: #666666;
				@include font-size(14);
				span{
					&.caption{
						@include font-size(10);
					}
				}
			}
		}
	}
	&.plivacy{
		padding: 1rem 0 0;
		div{
			&.wrap{
				padding: .5rem;
				color: #f04362;
				border: 2px solid #f04362;
				border-radius: 5px;
				background-color: #ffe7eb;
				@include font-size(11);
				a{
					text-decoration: underline;
					color: #f04362;
				}
				ul{
					padding: .5rem 0;
					li{
						font-weight: bold;
						margin-bottom: .4rem;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	&.check{
		padding: 1rem 1rem;
		p{
			&.lead{
				padding: 1rem 0;
				strong{
					font-weight: bold;
					color: #f04362;
				}
				a{
					text-decoration: underline;
				}
			}
		}
	}
}

section{
	p{
		&.lead{
			color: #666666;
			@include font-size(14);
			span{
				&.caption{
					@include font-size(10);
				}
			}
		}
	}
	&.form,
	&.entryform{
		padding: 0 0 1rem;
		dl{
			padding: 1rem;
			border-radius: 5px;
			background-color: #eeeeee;
			box-shadow: 0 4px 0 0 #cccccc;
			dt{
				font-weight: bold;
				width: 100%;
				margin-bottom: .8rem;
				padding-bottom: .3rem;
				letter-spacing: .1rem;
				border-bottom: 1px solid #999999;
				@include font-size(14);
				span{
					&.req{
						line-height: 1;
						display: inline-block;
						margin-left: .5rem;
						padding: .2rem .8rem;
						color: #ffffff;
						border-radius: 20px;
						background-color: #f04362;
						@include font-size(11);
					}
					&.opt{
						line-height: 1;
						display: inline-block;
						margin-left: .5rem;
						padding: .2rem .8rem;
						color: #444444;
						border: 1px solid #999999;
						border-radius: 20px;
						background-color: #ffffff;
						@include font-size(11);
					}
				}
			}
			dd{
				margin-bottom: .8rem;
				padding-bottom: .3rem;
				&.last{
					margin-bottom: 0;
				}
				p{
					&.push{
						line-height: 1.5;
						margin-bottom: .8rem;
						@include font-size(11);
						strong{
							font-weight: bold;
							color: #f04362;
						}
					}
					&.prompt{
						font-weight: bold;
						line-height: 1.5;
						margin-bottom: .2rem;
						color: #f04362;
						@include font-size(10);
						strong{
							font-weight: bold;
							color: #f04362;
						}
					}
				}
				span{
					&.atmark{
						font-weight: bold;
						display: inline-block;
						padding-left: .5rem;
						vertical-align: -1.3rem;
						color: #666666;
						@include font-size(22);
					}
					&.unit{
						font-weight: bold;
						display: inline-block;
						padding-left: .5rem;
						vertical-align: -1.3rem;
						color: #666666;
						@include font-size(22);
					}
				}
				div{
					&.wrap{
						margin-bottom: 1rem;
						@extend .clearfix;
					}
				}
			}
		}
	}
}

footer{
	padding: .4rem 0;
	text-align: center;
	background-color: #0060c3;
	small{
		font-weight: bold;
		display: inline;
		text-align: center;
		color: #ffffff;
		@include font-roboto;
		@include font-size(9);
	}
}

textarea,
input[type='text'],
input[type='tel'],
input[type='email']{
	max-width: 100%;
	height: 2rem;
	padding: 1.5rem .5rem;
	vertical-align: top;
	border: 3px solid #666666;
	border-radius: 5px;
	@include font-size(26);
	&:focus{
		border: 3px solid #f04362;
	}
	&.email{
		width: 80%;
	}
	&.profile{
		width: 40%;
	}
}

textarea{
	width: 100%;
	max-width: 100%;
	height: 10rem;
	padding: .5rem;
	@include font-size(18);
}

input[type='file']{
	margin-bottom: .8rem;
}

select{
	width: 100%;
	max-width: 100%;
	max-width: 100%;
	height: 4rem;
	margin-top: 1rem;
	padding: 0 .5rem;
	border: 3px solid #666666;
	border-radius: 5px;
	background-repeat: no-repeat;
	@include font-size(26);
}

.button{
	width: 100%;
	padding: 1rem 0;
	text-align: center;
	input[type='image']{
		max-width: 100%;
	}
	input[type='button'],
	input[type='submit']{
		line-height: 1;
		box-sizing: border-box;
		max-width: 100%;
		padding: 24px 32px 24px 32px;
		cursor: pointer;
		border: 0 none transparent;
		border-radius: 3px;
		outline: 0 none transparent;
		appearance: none;
		&.error{
			font-weight: bold;
			cursor: no-drop;
			color: #666666;
			background-color: #cccccc;
			box-shadow: 0 4px 0 0 darken(#cccccc, 10%);
			@include font-size(16);
		}
		&.confirm{
			font-weight: bold;
			color: #ffffff;
			background-color: orange;
			box-shadow: 0 4px 0 0 darken(orange, 10%);
			@include font-size(16);
		}
		&.submit{
			font-weight: bold;
			color: #fffa00;
			background-color: #f04362;
			box-shadow: 0 4px 0 0 darken(#f04362, 10%);
			@include font-size(16);
		}
	}
}
