/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
@charset 'UTF-8';
/* Google WebFont Droid
===================================================*/
// @import url(http://fonts.googleapis.com/css?family=Droid+Sans:400,700);
// @mixin font-droid{
// 	font-family: 'Droid Sans';
// }
/* Google WebFont Roboto
===================================================*/
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);
@mixin font-roboto{
	font-family: 'Roboto Condensed';
}