/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
@charset 'UTF-8';

/* Hn
===================================================*/
h2{
	margin: 0;
	padding: 0;
	.col-2 &{
		width: 470px;
	}
}

.h2{
	width: 470px;
}























































































































































































































































































































































































































































































