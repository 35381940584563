/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
@charset 'UTF-8';
textarea,
select,
button,
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week']{
	font-size: 1em;
	box-sizing: padding-box;
	height: 1em;
	margin: 0;
	padding: 0;
	text-align: left;
	vertical-align: middle;
	color: #000;
	border: 0 none #fff;
	border-radius: 0;
	outline: 0;
	background-color: #fff;
	-webkit-appearance: none;
}

select{
	font-size: .875em;
	padding: 0 25px 0 8px;
	background: #fff url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
	background-position: 97% center;
}

button{
	cursor: pointer;
	border: 0 none #fff;
	background-color: transparent;
}