@charset "UTF-8";
/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* Assets
===================================================*/
/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* Reset
===================================================*/
@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,300,700);
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
button {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: 0;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent;
}

strong,
th {
  font-weight: normal;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th {
  text-align: left;
}

hr,
legend {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
fieldset {
  border: 0;
}

li {
  list-style-type: none;
}

input,
select {
  position: relative;
  top: 0;
}

/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* TEXT COLOR
===================================================*/
/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* Fontsize
===================================================*/
/* Antialiased
===================================================*/
/* Link Color
===================================================*/
/* Text Decoration
===================================================*/
/* Text Overflow
===================================================*/
/* Background Font
===================================================*/
/* Animation of Scale
===================================================*/
/* Placeholder
===================================================*/
/*--------------------------------------------------
CSS Information
File name: _topic.css
Created: 2015-10-25
Last update: 2015-10-25 by Shuji Kama
Author: Shuji Kama (s.kama@dirtypop.jp)
--------------------------------------------------*/
/* extend
/* サイト全体で使用するcssを表示
===================================================*/
/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* Google WebFont Droid
===================================================*/
/* Google WebFont Roboto
===================================================*/
/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
textarea,
select,
button,
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'] {
  font-size: 1em;
  box-sizing: padding-box;
  height: 1em;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: middle;
  color: #000;
  border: 0 none #fff;
  border-radius: 0;
  outline: 0;
  background-color: #fff;
  -webkit-appearance: none;
}

select {
  font-size: .875em;
  padding: 0 25px 0 8px;
  background: #fff url(data:image/png;base64,R0lGODlhDQAEAIAAAAAAAP8A/yH5BAEHAAEALAAAAAANAAQAAAILhA+hG5jMDpxvhgIAOw==);
  background-position: 97% center;
}

button {
  cursor: pointer;
  border: 0 none #fff;
  background-color: transparent;
}

/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* TITLE
===================================================*/
[class*=".effect-"] {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}

.effect-fade {
  opacity: 0;
}

.effect-fade.in {
  opacity: 1;
}

.effect-slide-left {
  transform: translate3d(-30%, 0, 0);
  opacity: 0;
}

.effect-slide-left.in {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-slide-right {
  transform: translate3d(30%, 0, 0);
  opacity: 0;
}

.effect-slide-right.in {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-slide-top {
  transform: translate3d(0, -30%, 0);
  opacity: 0;
}

.effect-slide-top.in {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-slide-bottom {
  transform: translate3d(0, 30%, 0);
  opacity: 0;
}

.effect-slide-bottom.in {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.effect-pop {
  opacity: 0;
}

.effect-pop.in {
  animation: pop 0.6s ease-out 0.5s both;
  opacity: 1 \9;
}

@-webkit-keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-o-keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.effect-fall {
  opacity: 0;
  transform: scale(1.5);
}

.effect-fall.in {
  transform: scale(1);
  opacity: 1;
}

.effect-perspective {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}

.effect-helix {
  transform-style: preserve-3d;
  opacity: 0;
  transform: rotateY(-360deg);
}

.effect-helix.in {
  opacity: 1;
  transform: rotateY(0);
}

.effect-role {
  transform-style: preserve-3d;
  opacity: 0;
  transform: scale(0.5) rotate(-720deg);
}

.effect-role.in {
  opacity: 1;
  transform: scale(1) rotate(0);
}

/* Model
===================================================*/
/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* Layout
===================================================*/
html {
  max-width: 640px;
}

body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro W3', Osaka, 'メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic', Geneva, Arial, Verdana, sans-serif;
  font-size: 13px;
  line-height: 1.5;
  letter-spacing: .1ex;
  color: #666666;
  background: #ffffff none center top repeat;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body input:-moz-placeholder,
body textarea:-moz-placeholder {
  color: #aaaaaa;
}

body input::-moz-placeholder,
body textarea::-moz-placeholder {
  color: #aaaaaa;
}

body input:-ms-input-placeholder,
body textarea:-ms-input-placeholder {
  color: #aaaaaa;
}

body input::-webkit-input-placeholder,
body textarea::-webkit-input-placeholder {
  color: #aaaaaa;
}

body a,
body a:link,
body a:visited {
  text-decoration: none;
  color: #666666;
}

body a:hover,
body a:active {
  text-decoration: underline;
  color: #666666;
}

body a img {
  transition: .3s;
  opacity: 1;
}

body a:hover img {
  opacity: .8;
}

body * {
  box-sizing: border-box;
}

body img {
  max-width: 100%;
  vertical-align: top;
}

body i {
  vertical-align: -15%;
}

header {
  position: fixed;
  z-index: 9999;
  top: 0;
  border-bottom: 2px solid #437b34;
}

section {
  padding: 0;
}

section.catch {
  padding: 21% 0 0 0;
}

section.lead {
  padding: 1rem 1rem;
}

section.lead img {
  margin: .8rem 0;
}

section.entry {
  position: relative;
}

section.entry a {
  position: absolute;
  top: 35.3%;
  left: 24.4%;
  width: 72.8%;
}

section.point {
  padding: 1rem 1rem;
}

section.point img {
  margin: .8rem 0;
}

section.voice {
  padding: 1rem 1rem;
}

section.shop {
  padding: 1rem 1rem;
}

section.form {
  padding: 1rem;
}

section.form p.campaign {
  font-weight: bold;
  margin-top: 1rem;
  padding: 1rem;
  color: #fdf100;
  border-radius: 5px;
  background-color: #ff4a4a;
  font-size: 14px;
  font-size: 0.875rem;
}

section.form p.campaign strong {
  font-weight: bold;
}

section.form p.campaign span.caption {
  font-weight: normal;
  display: inline-block;
  padding-top: .5rem;
  color: #ffffff;
  font-size: 10px;
  font-size: 0.625rem;
}

section.form p.lead {
  padding: 1rem 0;
  color: #437b34;
  color: #666666;
  font-size: 14px;
  font-size: 0.875rem;
}

section.form p.lead span.caption {
  font-size: 10px;
  font-size: 0.625rem;
}

section.plivacy {
  padding: 1rem 0 0;
}

section.plivacy div.wrap {
  padding: .5rem;
  color: #f04362;
  border: 2px solid #f04362;
  border-radius: 5px;
  background-color: #ffe7eb;
  font-size: 11px;
  font-size: 0.6875rem;
}

section.plivacy div.wrap a {
  text-decoration: underline;
  color: #f04362;
}

section.plivacy div.wrap ul {
  padding: .5rem 0;
}

section.plivacy div.wrap ul li {
  font-weight: bold;
  margin-bottom: .4rem;
}

section.plivacy div.wrap ul li:last-child {
  margin-bottom: 0;
}

section.check {
  padding: 1rem 1rem;
}

section.check p.lead {
  padding: 1rem 0;
}

section.check p.lead strong {
  font-weight: bold;
  color: #f04362;
}

section.check p.lead a {
  text-decoration: underline;
}

section p.lead {
  color: #666666;
  font-size: 14px;
  font-size: 0.875rem;
}

section p.lead span.caption {
  font-size: 10px;
  font-size: 0.625rem;
}

section.form, section.entryform {
  padding: 0 0 1rem;
}

section.form dl, section.entryform dl {
  padding: 1rem;
  border-radius: 5px;
  background-color: #eeeeee;
  box-shadow: 0 4px 0 0 #cccccc;
}

section.form dl dt, section.entryform dl dt {
  font-weight: bold;
  width: 100%;
  margin-bottom: .8rem;
  padding-bottom: .3rem;
  letter-spacing: .1rem;
  border-bottom: 1px solid #999999;
  font-size: 14px;
  font-size: 0.875rem;
}

section.form dl dt span.req, section.entryform dl dt span.req {
  line-height: 1;
  display: inline-block;
  margin-left: .5rem;
  padding: .2rem .8rem;
  color: #ffffff;
  border-radius: 20px;
  background-color: #f04362;
  font-size: 11px;
  font-size: 0.6875rem;
}

section.form dl dt span.opt, section.entryform dl dt span.opt {
  line-height: 1;
  display: inline-block;
  margin-left: .5rem;
  padding: .2rem .8rem;
  color: #444444;
  border: 1px solid #999999;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 11px;
  font-size: 0.6875rem;
}

section.form dl dd, section.entryform dl dd {
  margin-bottom: .8rem;
  padding-bottom: .3rem;
}

section.form dl dd.last, section.entryform dl dd.last {
  margin-bottom: 0;
}

section.form dl dd p.push, section.entryform dl dd p.push {
  line-height: 1.5;
  margin-bottom: .8rem;
  font-size: 11px;
  font-size: 0.6875rem;
}

section.form dl dd p.push strong, section.entryform dl dd p.push strong {
  font-weight: bold;
  color: #f04362;
}

section.form dl dd p.prompt, section.entryform dl dd p.prompt {
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: .2rem;
  color: #f04362;
  font-size: 10px;
  font-size: 0.625rem;
}

section.form dl dd p.prompt strong, section.entryform dl dd p.prompt strong {
  font-weight: bold;
  color: #f04362;
}

section.form dl dd span.atmark, section.entryform dl dd span.atmark {
  font-weight: bold;
  display: inline-block;
  padding-left: .5rem;
  vertical-align: -1.3rem;
  color: #666666;
  font-size: 22px;
  font-size: 1.375rem;
}

section.form dl dd span.unit, section.entryform dl dd span.unit {
  font-weight: bold;
  display: inline-block;
  padding-left: .5rem;
  vertical-align: -1.3rem;
  color: #666666;
  font-size: 22px;
  font-size: 1.375rem;
}

section.form dl dd div.wrap, section.entryform dl dd div.wrap {
  margin-bottom: 1rem;
}

footer {
  padding: .4rem 0;
  text-align: center;
  background-color: #0060c3;
}

footer small {
  font-weight: bold;
  display: inline;
  text-align: center;
  color: #ffffff;
  font-family: 'Roboto Condensed';
  font-size: 9px;
  font-size: 0.5625rem;
}

textarea,
input[type='text'],
input[type='tel'],
input[type='email'] {
  max-width: 100%;
  height: 2rem;
  padding: 1.5rem .5rem;
  vertical-align: top;
  border: 3px solid #666666;
  border-radius: 5px;
  font-size: 26px;
  font-size: 1.625rem;
}

textarea:focus,
input[type='text']:focus,
input[type='tel']:focus,
input[type='email']:focus {
  border: 3px solid #f04362;
}

textarea.email,
input[type='text'].email,
input[type='tel'].email,
input[type='email'].email {
  width: 80%;
}

textarea.profile,
input[type='text'].profile,
input[type='tel'].profile,
input[type='email'].profile {
  width: 40%;
}

textarea {
  width: 100%;
  max-width: 100%;
  height: 10rem;
  padding: .5rem;
  font-size: 18px;
  font-size: 1.125rem;
}

input[type='file'] {
  margin-bottom: .8rem;
}

select {
  width: 100%;
  max-width: 100%;
  max-width: 100%;
  height: 4rem;
  margin-top: 1rem;
  padding: 0 .5rem;
  border: 3px solid #666666;
  border-radius: 5px;
  background-repeat: no-repeat;
  font-size: 26px;
  font-size: 1.625rem;
}

.button {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
}

.button input[type='image'] {
  max-width: 100%;
}

.button input[type='button'],
.button input[type='submit'] {
  line-height: 1;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px 32px 24px 32px;
  cursor: pointer;
  border: 0 none transparent;
  border-radius: 3px;
  outline: 0 none transparent;
  appearance: none;
}

.button input[type='button'].error,
.button input[type='submit'].error {
  font-weight: bold;
  cursor: no-drop;
  color: #666666;
  background-color: #cccccc;
  box-shadow: 0 4px 0 0 #b3b3b3;
  font-size: 16px;
  font-size: 1rem;
}

.button input[type='button'].confirm,
.button input[type='submit'].confirm {
  font-weight: bold;
  color: #ffffff;
  background-color: orange;
  box-shadow: 0 4px 0 0 #cc8400;
  font-size: 16px;
  font-size: 1rem;
}

.button input[type='button'].submit,
.button input[type='submit'].submit {
  font-weight: bold;
  color: #fffa00;
  background-color: #f04362;
  box-shadow: 0 4px 0 0 #ec143b;
  font-size: 16px;
  font-size: 1rem;
}

/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
/* Hn
===================================================*/
h2 {
  margin: 0;
  padding: 0;
}

.col-2 h2 {
  width: 470px;
}

.h2 {
  width: 470px;
}

/* jQuery
===================================================*/
