/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
@charset 'UTF-8';

/* TITLE
===================================================*/

@charset 'UTF-8';

[class*=".effect-"] {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	perspective: 1000;
}
.effect-fade {
	opacity: 0;
}
.effect-fade.in {
	opacity: 1;
}
.effect-slide-left {
	transform: translate3d(-30%, 0, 0);
	opacity: 0;
}
.effect-slide-left.in {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}
.effect-slide-right {
	transform: translate3d(30%, 0, 0);
	opacity: 0;
}
.effect-slide-right.in {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}
.effect-slide-top {
	transform: translate3d(0, -30%, 0);
	opacity: 0;
}
.effect-slide-top.in {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}
.effect-slide-bottom {
	transform: translate3d(0, 30%, 0);
	opacity: 0;
}
.effect-slide-bottom.in {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}
.effect-pop {
	opacity: 0;
}
.effect-pop.in {
	animation: pop 0.6s ease-out 0.5s both;
	opacity: 1 \9;
}
@-webkit-keyframes pop {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@-moz-keyframes pop {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@-o-keyframes pop {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes pop {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
.effect-fall {
	opacity: 0;
	transform: scale(1.5);
}
.effect-fall.in {
	transform: scale(1);
	opacity: 1;
}
.effect-perspective {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}
.effect-helix {
	transform-style: preserve-3d;
	opacity: 0;
	transform: rotateY(-360deg);
}
.effect-helix.in {
	opacity: 1;
	transform: rotateY(0);
}

.effect-role {
	transform-style: preserve-3d;
	opacity: 0;
	transform: scale(.5) rotate(-720deg);
}
.effect-role.in {
	opacity: 1;
	transform: scale(1) rotate(0);
}
