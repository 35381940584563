/*--------------------------------------------------
CSS Information
File name: style.css (http://dirtypop.jp)
Summary: Common styles
Created: 2014-05-06
Last update: 2014-05-06 by Shuji Kama
Author: Shuji Kama
--------------------------------------------------*/
@charset 'UTF-8';

@charset 'UTF-8';

/* Fontsize
===================================================*/
@mixin font-size($size: 13, $base: 16){
	font-size: $size + px;
	font-size: ($size / $base) * 1rem;
}

/* Antialiased
===================================================*/
@mixin font-smoothing($value: antialiased){
	@if $value == antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	}
	@else {
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: auto;
	}
}

/* Link Color
===================================================*/
@mixin default-link-color($link-color: $link-text-color, $hover-color: $hover-text-color){
	a{
	color: $link-color;
		&:hover,
		&:active,
		&:focus{
			color: $hover-color;
		}
	}
}

/* Text Decoration
===================================================*/
@mixin text-decoration(){
	a{
		&:link,
		&:hover,
		&:active,
		&:focus{
			text-decoration: none;
		}
	}
}

/* Text Overflow
===================================================*/
@mixin text-overflow(){
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/* Background Font
===================================================*/
@mixin background-font($color, $content, $top){
	position: relative;
		&:before {
		content: $content;
		font-family: icomoon;
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		color: $color;
		font-size: inherit;
		padding-right: 0.5em;
		position: absolute;
		top: $top;
		left: 8px;
	}
}

/* Animation of Scale
===================================================*/
@mixin animation-scale(){
	transition: all 0.2s ease-in-out;
	transform-origin: center center;
	&:hover{
		transform: scale(1.1 1.1);
	}
}

/* Placeholder
===================================================*/
@mixin placeholder($color){
	input:-moz-placeholder,
	textarea:-moz-placeholder{
		color: $color;
	}
	input::-moz-placeholder,
	textarea::-moz-placeholder{
		color: $color;
	}
	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder{
		color: $color;
	}
	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder{
		color: $color;
	}
}